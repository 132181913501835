import React from "react";
import { ReactComponent as Logo } from './img/SVG/logo.svg';
import { Navbar, Container } from 'react-bootstrap';
import { useDebug } from "./Hooks";

const Header = props => {
  const { debug } = useDebug();

  return <Navbar>
  <Container>
    { debug && <Navbar.Brand href="/"><Logo /></Navbar.Brand> }
    { !debug && <Navbar.Brand><Logo /></Navbar.Brand> }
    
    {/* <Navbar.Toggle />
    <Navbar.Collapse className="justify-content-end">
      <Navbar.Text>
        Signed in as: <a href="#login">Mark Otto</a>
      </Navbar.Text>
    </Navbar.Collapse> */}
  </Container>
</Navbar>
;
};

export default Header;