import React, { useState, useEffect }  from 'react';
import { Alert } from 'react-bootstrap';
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { useTranslation } from 'react-i18next';
import { Api } from './Api';
import TextPage from './TextPage';

import {
    useParams
  } from "react-router-dom";

import {
  useNavigate,
} from "react-router-dom";

function Unsubscription(props) {
  const { t } = useTranslation();
  const [unsubscribed, setUnsubscribed] = useState(false);

  const { actions, state } = useStateMachine({ updateAction });

  let navigate = useNavigate();
  let { registration_id, activation_token } = useParams();

  const unsubscribe_registration = async (registration_id, activation_token) => {

    if(unsubscribed) {
        console.log('Already unsubscribed, bailing...');
        return;
    }

    actions.updateAction({unsubscription: {registration_id: registration_id, activation_token: activation_token}});

    const {result: res, error: err} = await new Api().unsubscribe(registration_id, activation_token);

    if(res) {
        if(res.data.activation_token) {
            actions.updateAction({unsubscription: {...state.unsubscription, status: true, message: t('messages.unsubscription.successful')}});
            setUnsubscribed(true);
            navigate('/unsubscribe/thankyou')
        } else {
            actions.updateAction({unsubscription: {...state.unsubscription, status: false, message: t('messages.unsubscription.failed')}});
        }

        console.log('Result', res.data);

    } else if(err) {
        if(err.response) {
            if(err.response.data.code) {
                actions.updateAction({unsubscription: {...state.unsubscription, status: false, message: err.response.data.message, code: err.response.data.code}});
            } else if(err.response.status === 404) {
                actions.updateAction({unsubscription: {...state.unsubscription, status: false, message: t('messages.unsubscription.errors.registration_id_activation_id_combo_not_found')}});
            } else {
                actions.updateAction({unsubscription: {...state.unsubscription, status: false, message: t('messages.unsubscription.errors.request_failed_try_again')}});
                console.log('error', err);
            }
        } else if (err.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(err.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', err.message);
        }
        console.log(err.config);
    }

  };  

  console.log('Props', props);
  console.log('Params', registration_id, activation_token);
  const show_alert = state.unsubscription?.message;
  
  useEffect(() => {
    unsubscribe_registration(registration_id, activation_token);
  }, []);  
  
  return <TextPage
    message_key="unsubscription"
    extra={ show_alert && <Alert variant={state.unsubscription.status ? 'success' : 'danger'}>{ state.unsubscription.message }</Alert> }
  />
}

export default Unsubscription;