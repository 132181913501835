import React, { useState, useEffect }  from 'react';
import { Alert } from 'react-bootstrap';
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { useTranslation } from 'react-i18next';
import { Api } from './Api';
import TextPage from './TextPage';

import {
    useParams
  } from "react-router-dom";

import {
  useNavigate,
} from "react-router-dom";

function Activation(props) {
  const { t } = useTranslation();
  const [activated, setActivated] = useState(false);

  const { actions, state } = useStateMachine({ updateAction });

  let navigate = useNavigate();
  let { registration_id, activation_token } = useParams();

  const activate_registration = async (registration_id, activation_token) => {

    if(activated) {
        console.log('Already activated, bailing...');
        return;
    }

    actions.updateAction({activation: {registration_id: registration_id, activation_token: activation_token}});

    const {result: res, error: err} = await new Api().activate(registration_id, activation_token);

    if(res) {
        if(res.data.activation_token) {
            actions.updateAction({activation: {...state.activation, status: true, message: t('messages.activation.successful')}});
            setActivated(true);
            navigate('/activate/thankyou');
        } else {
            actions.updateAction({activation: {...state.activation, status: false, message: t('messages.activation.failed')}});
        }

        console.log('Result', res.data);

    } else if(err) {
        if(err.response) {
            if(err.response.data.code) {
                actions.updateAction({activation: {...state.activation, status: false, message: err.response.data.message, code: err.response.data.code}});
            } else if(err.response.status === 404) {
                actions.updateAction({activation: {...state.activation, status: false, message: t('messages.activation.errors.registration_id_activation_id_combo_not_found')}});
            } else {
                actions.updateAction({activation: {...state.activation, status: false, message: t('messages.activation.errors.activation_failed_try_again.')}});
                console.log('error', err);
            }
        } else if (err.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log(err.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', err.message);
        }
        console.log(err.config);
    }

  };  

  console.log('Props', props);
  console.log('Params', registration_id, activation_token);
  const show_alert = state.activation?.message;
  
  useEffect(() => {
    activate_registration(registration_id, activation_token);
  }, []);  
  
  return <TextPage
    message_key="activation"
    extra={ show_alert && <Alert variant={state.activation.status ? 'success' : 'danger'}>{ state.activation.message }</Alert> }
  />
}

export default Activation;