import React from 'react';
import { Form, Button, Alert, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";

function LoginForm(props) {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { actions, state } = useStateMachine({ updateAction });

  const onSubmit = data => {
    
    actions.updateAction({login: {status: null, ...data}});

    // try to login
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data)
  }

    fetch('https://backend.tunnel.weare-rooms.com/frontend/auth/login/', requestOptions)
    .then(res => res.json())
    .then((res_data) => {
      
      if(res_data.auth_token) {
        actions.updateAction({login: {status: true, message: 'Login successful.'}});
      } else {
        actions.updateAction({login: {status: false, message: 'Login failed.'}});
      }
      console.log('result', res_data)
    })
    .catch((err) => {
      actions.updateAction({login: {status: false, message: 'Login request failed. Please try again.'}});
      console.log('error', err);
    })    
  };  

  console.log('Errors', errors);
  console.log('Props', props);
  console.log('State', state);
  console.log('Actions', actions);
  
  return (
    <Col>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <h1>Login</h1>

        <Alert show={ state.login.status === true || state.login.status === false } variant={ state.login.status ? 'success' : 'danger'}>
          <p>{ state.login.message }</p>
        </Alert>

        <Form.Control type="text" placeholder="Email" {...register("email", {required: true, pattern: /^\S+@\S+$/i})} />
        <Form.Control type="password" placeholder="Password" {...register("password", {required: true, maxLength: 100})} />

        <Button type="submit">Submit</Button>
      </Form>
    </Col>
  );
}

export default LoginForm;