import React from "react";
import { Link } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { useTranslation } from 'react-i18next';
import ContentRow from "./ContentRow";
import ContentBlock from "./ContentBlock";
import lngs from './Languages';

const Debug = props => {
  const { actions, state } = useStateMachine({updateAction});
  const { i18n } = useTranslation();

  const updateLanguage = (language) => {
    actions.updateAction({app: {...state.app, language: language }});
  }

  return <ContentRow>
    <ContentBlock
      model="default"
      title="Debug"
    >
      <ul>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/register">Register</Link></li>
        <li><Link to="/register/starter">Register starter</Link></li>
        <li><Link to="/register/pro">Register pro</Link></li>
        <li><Link to="/register/enterprise">Register enterprise</Link></li>
        <li><Link to="/register/thankyou">Register Thank You</Link></li>
        <li><Link to="/activate/thankyou">Activate Thank You</Link></li>
        <li><Link to="/login">Login</Link></li>
        <li><Link to="/result">Result</Link></li>
      </ul>

      <div>
        {Object.keys(lngs).map((lng) => (
          <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => updateLanguage(lng)}>
            {lngs[lng].nativeName}
          </button>
        ))}
      </div>
    </ContentBlock>
  </ContentRow>
};

export default Debug;