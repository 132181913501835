import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          messages: {
            thank_you: {
              for_registering: {
                headline: 'Thank you!',
                teaser: 'Your registration was successful. You\'ll be recieving an email from us with further instructions. Should the email not arrive within the next 2 hours, please get in touch with our <a href="mailto:support@weare-rooms.com">customer support</a>.'
              },
              for_activating: {
                headline: 'Thank you!',
                teaser: 'Your activation was successful. You\'ll be recieving an email from us with further instructions. Should the email not arrive within the next 2 hours, please get in touch with our <a href="mailto:support@weare-rooms.com">customer support</a>.'
              },
              for_unsubscribing: {
                headline: 'Sad to see you go!',
                teaser: 'Your unsubscription was successful. You will no longer receive communication from us regarding your registration. Nevertheless thank you for your initial interest in WeAre Rooms!'
              }
            },
            products: {
              headline: 'Shop',
              select_a_package: 'Select a WeAre package and start your personal VR experience today.',
              enterprise_make_an_appointment: 'Would you like to learn more about our enterprise solution? Make an appointment for a personal consultation with our sales team.',
              register_now: 'Register now',
              after_14_days: 'After 14 days',
              per_user_per_month: '/ user / month',
              includes: 'includes',
              book_consultation: 'Book a consultation',
              lets_talk: 'Let´s talk',
              about_custom_package: 'about a custom package',
              features: {
                glb_import: 'GLB import',
                advanced_cad_import: 'Advanced CAD import',
                prototype_walkthrough: 'Prototype walkthrough',
                exploded_view: 'Exploded view',
                capturing_virtual_videos: 'Capturing virtual videos',
                xray_view_and_measurements: 'X-Ray view & measurement',
                virtual_whiteboards_and_notes: 'Virtual whiteboard & notes',
                newest_updates: 'Newest updates',
                more_than_5_users: '5+ users',
                vip_trainings: 'VIP trainings',
                personal_support: 'Personal support',
                customizations: 'Customizations',
                on_premise: 'On-premise',
                apis_and_integrations: 'APIs & integrations',
                hardware_support_via_partner: 'Hardware support via partner'
              }
            },
            activation: {
              headline: 'Activation',
              teaser: 'Thank you for activating your registration. Please be patient while we setup your account.',
              successful: 'Activation successful.',
              failed: 'Activation failed.',
              errors: {
                registration_id_activation_id_combo_not_found: 'Registration ID / Activation token combo not found.',
                request_failed_try_again: 'Activation request failed. Please try again.'
              }
            },
            unsubscription: {
              headline: 'Unsubscription',
              teaser: 'We respect your wish to not receive any further communication regarding your registration. Please be patient while we update your preferences.',
              successful: 'Unsubscription successful.',
              failed: 'Unsubscription failed.',
              errors: {
                registration_id_activation_id_combo_not_found: 'Registration ID / Activation token combo not found.',
                request_failed_try_again: 'Unsubscription request failed. Please try again.'
              }
            },
            registration: {
              headline: 'Registration',
              teaser: "Please fill out the registration form below. We'll then send you an email with further instructions. In case of any questions, please feel free to get in touch.",
              title: 'Registration',
              first_name: 'First name',
              last_name: 'Last name',
              job_title: 'Job title',
              company_name: 'Company',
              street: 'Street',
              zip: 'ZIP',
              city: 'City',
              country: 'Country',
              email: 'Email',
              phone_number: 'Phone number',
              password: 'Password',
              password_confirm: 'Confirm Password',
              privacy_accepted: 'I have read and agree to the privacy policy.',
              terms_accepted: 'I have read and agree to the <a href="https://share.weare-rooms.com/public/demo/20220503_WeAre_AGBs_Datenschutzbestimmungen.pdf" target="_blank">terms and privacy policy for the free demo version of the WeAre software</a>.',
              register: 'Register',
              successful: 'Registration successful.',
              failed: 'Registration failed.',
              fields_with_asterisk_are_required: '* Required fields',
              errors: {
                first_name: {
                  required: 'First name is required'
                },
                last_name: {
                  required: 'Last name is required'
                },
                company_name: {
                  required: 'Company is required'
                },
                street: {
                  required: 'Street is required'
                },
                zip: {
                  required: 'ZIP is required'
                },
                city: {
                  required: 'City is required'
                },
                country: {
                  required: 'Country is required'
                },
                email: {
                  required: 'Email is required',
                  invalid: 'Email is invalid'
                },
                password: {
                  required: 'Password is required',
                  min_length: 'Password must be at least 6 characters',
                  max_length: 'Password must not exceed 40 characters'
                },
                password_confirm: {
                  required: 'Confirm Password is required',
                  does_not_match: 'Confirm Password does not match'
                },
                privacy_accepted: {
                  required: 'Accepting the Privacy Agreement is required'
                },
                terms_accepted: {
                  required: 'Accepting the terms and privacy policy for the free demo version of the WeAre software is required'
                },
                request_failed_try_again: 'Registration request failed. Please try again.'
              }
            }
          }
        }
      },
      de: {
        translation: {
          messages: {
            thank_you: {
              for_registering: {
                headline: 'Vielen Dank!',
                teaser: 'Ihre Registrierung war erfolgreich. Sie erhalten von uns eine E-Mail mit den weiteren Schritten. Sollten Sie diese nicht innerhalb der nächsten 2 Stunden erhalten, kontaktieren Sie bitte unseren <a href="mailto:support@weare-rooms.com">Kundensupport</a>.'
              },
              for_activating: {
                headline: 'Vielen Dank!',
                teaser: 'Ihre Aktivierung war erfolgreich. Sie erhalten von uns eine E-Mail mit den weiteren Schritten. Sollten Sie diese nicht innerhalb der nächsten 2 Stunden erhalten, kontaktieren Sie bitte unseren <a href="mailto:support@weare-rooms.com">Kundensupport</a>.'
              },
              for_unsubscribing: {
                headline: 'Wir vermissen Sie schon jetzt!',
                teaser: 'Ihre Kommunikationseinstellungen wurden erfolgreich angepasst. Sie erhalten in Zukunft keine weiteren Benachrichtigungen bzgl. Ihrer Registrierung. Wir bedanken uns dennoch für Ihr ursprüngliches Interesse an WeAre Rooms!'
              }

            },
            products: {
              headline: 'Shop',
              select_a_package: 'Wählen Sie ein WeAre Paket aus und starten Sie noch heute mit Ihrem persönlichen VR-Erlebnis.',
              enterprise_make_an_appointment: 'Sie möchten mehr über unsere Enterprise-Lösung erfahren? Vereinbaren Sie einen persönlichen Beratungstermin mit unserem Vertriebsteam.',
              register_now: 'Jetzt registrieren',
              after_14_days: 'Nach 14 Tagen',
              per_user_per_month: '/ Nutzer / Monat',
              includes: 'Funktionen',
              book_consultation: 'Beratung vereinbaren',
              lets_talk: 'Sprechen Sie uns an!',
              about_custom_package: 'Benutzerdefiniertes Paket',
              features: {
                glb_import: 'GLB Import',
                advanced_cad_import: 'Erweiterter CAD Import',
                prototype_walkthrough: 'Prototypen Begehung',
                exploded_view: 'Explosionsansicht',
                capturing_virtual_videos: 'Aufnahme virtueller Videos',
                xray_view_and_measurements: 'X-Ray Röntgenansicht & Abmessungen',
                virtual_whiteboards_and_notes: 'Virtuelles Whiteboard & Notizen',
                newest_updates: 'Neueste Updates',
                more_than_5_users: '5+ Nutzer',
                vip_trainings: 'VIP Schulungen',
                personal_support: 'Persönlicher Support',
                customizations: 'Anpassungen',
                on_premise: 'On-Premise',
                apis_and_integrations: 'APIs & Integration',
                hardware_support_via_partner: 'Hardware Support via Partner'
              }
            },
            activation: {
              headline: 'Aktivierung',
              teaser: 'Vielen Dank für die Aktivierung Ihrer Registrierung. Bitte haben Sie etwas Geduld während wir Ihren Benutzeraccount einrichten.',
              successful: 'Aktivierung erfolgreich.',
              failed: 'Aktivierung fehlgeschlagen.',
              errors: {
                registration_id_activation_id_combo_not_found: 'Registrierungs-ID / Aktivierungstoken Kombination nicht gefunden.',
                request_failed_try_again: 'Aktivierung fehlgeschlagen. Bitte versuchen Sie es erneut.'
              }
            },
            unsubscription: {
              headline: 'Opt-Out',
              teaser: 'Wir respektieren Ihren Wunsch, keine weiteren Benachrichtigungen bzgl. Ihrer Registrierung zu erhalten. Bitte haben Sie etwas Geduld während wir Ihren Einstellungen anpassen.',
              successful: 'Opt-Out erfolgreich.',
              failed: 'Opt-Out fehlgeschlagen.',
              errors: {
                registration_id_activation_id_combo_not_found: 'Registrierungs-ID / Aktivierungstoken Kombination nicht gefunden.',
                request_failed_try_again: 'Opt-Out fehlgeschlagen. Bitte versuchen Sie es erneut.'
              }
            },
            registration: {
              headline: 'Registrierung',
              teaser: 'Bitte füllen Sie das Registrierungsformular aus. Wir schicken Ihnen im Anschluss eine E-Mail mit allen weiteren Informationen. Bei Fragen stehen wir Ihnen natürlich jederzeit gerne zur Verfügung.',
              title: 'Registrierung',
              first_name: 'Vorname',
              last_name: 'Nachname',
              job_title: 'Position',
              company_name: 'Firma',
              street: 'Straße',
              zip: 'Postleitzahl',
              city: 'Ort',
              country: 'Land',
              email: 'E-Mail',
              phone_number: 'Telefonnummer',
              password: 'Passwort',
              password_confirm: 'Passwortbestätigung',
              privacy_accepted: 'Ich habe die Datenschutzerklärung gelesen und akzeptiere diese.',
              terms_accepted: 'Ich habe die <a href="https://share.weare-rooms.com/public/demo/20220503_WeAre_AGBs_Datenschutzbestimmungen.pdf" target="_blank">AGBs & Datenschutzbestimmungen für die kostenlose Testnutzung der WeAre-Software</a> gelesen und akzeptiere diese.',
              register: 'Registrieren',
              successful: 'Registrierung erfolgreich.',
              failed: 'Registrierung fehlgeschlagen.',
              fields_with_asterisk_are_required: '* Notwendige Angaben',
              errors: {
                first_name: {
                  required: 'Vorname muss ausgefüllt werden'
                },
                last_name: {
                  required: 'Nachname muss ausgefüllt werden'
                },
                company_name: {
                  required: 'Firma muss ausgefüllt werden'
                },
                street: {
                  required: 'Straße muss ausgefüllt werden'
                },
                zip: {
                  required: 'Postleitzahl muss ausgefüllt werden'
                },
                city: {
                  required: 'Ort muss ausgefüllt werden'
                },
                country: {
                  required: 'Land muss ausgefüllt werden'
                },
                email: {
                  required: 'E-Mail muss ausgefüllt werden',
                  invalid: 'E-Mail ist ungültig'
                },
                password: {
                  required: 'Passwort muss ausgefüllt werden',
                  min_length: 'Passwort muss aus mindestens 6 Zeichen bestehen',
                  max_length: 'Passwort muss aus weniger als 40 Zeichen bestehen'
                },
                password_confirm: {
                  required: 'Passwortbestätigung muss ausgefüllt werden',
                  does_not_match: 'Passwortbestätigung stimmt nicht mit Passwort überein'
                },
                privacy_accepted: {
                  required: 'Die Datenschutzvereinbarung muss akzeptiert werden'
                },
                terms_accepted: {
                  required: 'Die AGBs & Datenschutzbestimmungen für die kostenlose Testnutzung der WeAre-Software müssen akzeptiert werden'
                },
                request_failed_try_again: 'Registrierung fehlgeschlagen. Bitte versuchen Sie es erneut.'
              }
            }
          }
        }
      }
    }
  });

export default i18n;
