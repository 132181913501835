import React from "react";
import { Col, Row } from 'react-bootstrap';

const ContentRow = props => {

  return <Row>
    <Col className="col-md-12">
      <div className="shop_3_teaser">
        { props.children }
      </div>
    </Col>
  </Row>

};

export default ContentRow;