import React from 'react';
import { useLocation } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { useTranslation } from 'react-i18next';
import lngs from './Languages';

export function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function useSetDebug(debug) {
    const { actions, state } = useStateMachine({ updateAction });
    console.log(actions, state);
    actions.updateAction({app: {...state.app, debug: debug }});
}

export function useDebug() {
    
    const query = useQuery();
    const { state } = useStateMachine({ updateAction });
    
    // debug flag handling
    const requested_debug = query.get('debug');
    const debug = requested_debug ? requested_debug === 'true' : state.app.debug;

    return {
        debug: debug,
        setDebug: useSetDebug
    };
}

function useSetLanguage(language) {
    const { actions, state } = useStateMachine({ updateAction });
    const { i18n } = useTranslation();

    if(state.app?.language !== language) {
        console.log('Updating state language to', language);
        actions.updateAction({app: {...state.app, language: language }});
    }

    if(i18n.language !== language) {
        console.log('Updating i18n language to', language);
        i18n.changeLanguage(language);    
    }
}

export function useLanguage() {
    const { i18n } = useTranslation();
    const { state } = useStateMachine({ updateAction });
    const query = useQuery();

    // language selection
    const requested_language = query.get('lang');
    console.log('Current i18n language:', i18n.language);
    console.log('Current state language:', state.app?.language);
    console.log('Requested language:', requested_language);
  
    if(lngs[requested_language]) {
        return {
            language: requested_language,
            setLanguage: useSetLanguage
        };
    } else {
        return {
            language: state.app?.language || i18n.language,
            setLanguage: useSetLanguage
        };
    }
}