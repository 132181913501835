import React from "react";
import ButtonLink from "./ButtonLink";

const ContentBlock = props => {
  return <div className={`shop_item ${props.className || ''}`}>
    <div className="shop_item_inner">
      <div className={`model ${props.model || ''}`}>
        <p>{props.title}</p>
      </div>
      { props.children }
      { props.link && props.linkTitle && <ButtonLink link={props.link} linkTitle={props.linkTitle}/>}
    </div>
  </div>
};

export default ContentBlock;