import React from "react";
import Starter from "./Starter";
import Pro from "./Pro";
import Enterprise from "./Enterprise";
import './Plan.scss';

const Plan = props => {
  
  const plan = props.plan || 'starter';
  const components = {
      starter: <Starter {... props} />,
      pro: <Pro {... props} />,
      enterprise: <Enterprise {... props} />
  }

  return (
      components[plan]
  );
};

export default Plan;