import React from "react";
import { useTranslation } from 'react-i18next';
import Teaser from "./Teaser";
import RegistrationForm from "./RegistrationForm";
import Page from "./Page";

const Registration = props => {
  const { t } = useTranslation();

  const teaser = props.teaser || <Teaser headline={t('messages.registration.headline')}><p>{t('messages.registration.teaser')}</p></Teaser>

  return <Page teaser={teaser}>
    <RegistrationForm {...props} />
  </Page>
};

export default Registration;