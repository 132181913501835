import React from "react";
import { useTranslation } from 'react-i18next';
import Page from "./Page";
import Teaser from "./Teaser";
import ContentRow from "./ContentRow";
import Plan from "./Plan";

const Shop = props => {
  const { t } = useTranslation();

  return <Page teaser={
    <Teaser headline={t('messages.products.headline')}>
      <p>{t('messages.products.select_a_package')}</p>
      <p>{t('messages.products.enterprise_make_an_appointment')}</p>
    </Teaser>
  }>
    <ContentRow>
      <Plan plan="starter" link="/register/starter" linkTitle={t('messages.products.register_now')} />
      <Plan plan="pro" link="/register/pro" linkTitle={t('messages.products.register_now')} />
      <Plan plan="enterprise" link="/register/enterprise" linkTitle={t('messages.products.book_consultation')} />
    </ContentRow>
  </Page>

};

export default Shop;