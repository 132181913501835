// import i18n (needs to be bundled ;))
import i18n from './i18n';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { StateMachineProvider, createStore } from 'little-state-machine';
import { BrowserRouter as Router, Routes, Route, Outlet } from "react-router-dom";
import { Col } from 'react-bootstrap';
import Registration from './Registration';
import LoginForm from './LoginForm';
import Result from './Result';
import Activation from './Activation';
import Unsubscription from './Unsubscription';
import TextPage from './TextPage';
import Shop from './Shop';

function log(store) {
  console.log(store);
  return store;
}

createStore(
  {
    registration: {},
    login: {},
    activation: {},
    app: {}
  },
  {
    middleWares: [ log ]
  },
);

ReactDOM.render(
  <React.StrictMode>
    <StateMachineProvider>
      <Router>
        <Routes>
          <Route path="/" element={<App />}>
            <Route path="register" element={<Outlet />}>
              <Route path="starter" element={<Registration plan="starter"/>} />
              <Route path="pro" element={<Registration plan="pro"/>} />
              <Route path="enterprise" element={<Registration plan="enterprise"/>} />
              <Route index element={<Registration plan="starter"/>} />
              <Route path="thankyou" element={
                <TextPage message_key="thank_you.for_registering"></TextPage>
              }/>
            </Route>
            <Route path="result" element={<Result />} />
            <Route path="activate" element={<Outlet />}>
              <Route path=":registration_id/:activation_token" element={<Activation />} />
              <Route path="thankyou" element={
                <TextPage message_key="thank_you.for_activating"></TextPage>
              }/>
            </Route>
            <Route path="invite" element={<Outlet />}>
              <Route path=":plan/:registration_id/:join_token/:encoded_company_name" element={<Registration invitation={true}/>} />
              <Route path="thankyou" element={
                  <Col>Thank you for joining!</Col>
                }/>
            </Route>
            <Route path="unsubscribe" element={<Outlet />}>
              <Route path=":registration_id/:activation_token" element={<Unsubscription />} />
              <Route path="thankyou" element={
                <TextPage message_key="thank_you.for_unsubscribing"></TextPage>
              }/>
            </Route>
            <Route index element={<Shop />}/>
            <Route path="login" element={<LoginForm />}/>
          </Route>
        </Routes>
      </Router>
    </StateMachineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
