import React from "react";
import { ReactComponent as Wave } from './img/SVG/Welle_neu_hinten_neu.svg';
import { Col } from 'react-bootstrap';

const Teaser = props => {
  return <section className="hero text_headlines custom_cols">
  <div className="bg_image">
    <figure className="desktop">
      <Wave />
    </figure>
    <div className="content">
      <div className="container-fluid">
        <div className="row">
          <Col className="col-6 vertical_centered">
            <div className="content_inner">
              <div className="headline big">
                <h1>{props.headline}</h1>
              </div>
              <div className="text">
                {props.children}
              </div>
            </div>
          </Col>
        </div>
      </div>
    </div>
  </div>
</section>
};

export default Teaser;