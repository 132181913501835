import React from "react";

const ButtonLink = props => {
  return (
    <div className="link_wrapper">
      <a className="btn_bg" href={props.link}>{props.linkTitle}</a>
    </div>        

  );
};

export default ButtonLink;