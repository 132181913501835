import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export class Api {

    constructor() {
        this.baseUrl = process.env.REACT_APP_API_BASE_URL;
    };

    getUrl(path) {
        return `${this.baseUrl}${path}`;
    };

    async call(config) {
        
        let data = {
            result: null,
            error: null
        };

        try {
            data.result = await axios.request(config);
        } catch(err) {
            data.error = err;
        }
        
        console.log('CALL => ', data);

        return data;
    };

    register(data) {
        return this.call(
            {
                url: this.getUrl(`/registrations/`),
                method: 'post',
                data: data
            }
        );
    };

    activate(registration_id, activation_token) {
        return this.call(
            {
                url: this.getUrl(`/registrations/${registration_id}/activate/`),
                method: 'post',
                data: {
                    activation_token: activation_token
                }
            }
        );
    };

    unsubscribe(registration_id, activation_token) {
        return this.call(
            {
                url: this.getUrl(`/registrations/${registration_id}/unsubscribe/`),
                method: 'post',
                data: {
                    activation_token: activation_token
                }
            }
        );
    };

}
