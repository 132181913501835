import React from "react";
import ContentBlock from "./ContentBlock";
import { useTranslation } from "react-i18next";

const Starter = props => {

  const { t } = useTranslation();

  return <ContentBlock {...props}
    model="starter"
    title="Starter"    
  >
    <div className="dauer">
      <p>{t('messages.products.after_14_days')}</p>
    </div>
    <div className="price">
      <p>99€</p>
    </div>
    <div className="billing">
      <p>{t('messages.products.per_user_per_month')}</p>
    </div>
    <div className="text">
      <h3>{t('messages.products.includes')}</h3>
      <p>
        + {t('messages.products.features.glb_import')}<br />
        + {t('messages.products.features.prototype_walkthrough')}<br />
        + {t('messages.products.features.exploded_view')}<br />
        + {t('messages.products.features.capturing_virtual_videos')}<br />
        + {t('messages.products.features.xray_view_and_measurements')}<br />
        + {t('messages.products.features.virtual_whiteboards_and_notes')}<br />
        + {t('messages.products.features.newest_updates')}<br />
        <br />
      </p>
    </div>
  </ContentBlock>
};

export default Starter;