import React, { useState }  from 'react';
import { Col, Row, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Api } from './Api';
import Plan from './Plan';
import { useLanguage } from './Hooks';

import countries from "i18n-iso-countries";
import english from "i18n-iso-countries/langs/en.json";
import german from "i18n-iso-countries/langs/de.json";

import {
  useParams,
  useNavigate,
} from "react-router-dom";

function RegistrationForm(props) {

  const { language } = useLanguage();

  console.log('Form language', language);
  countries.registerLocale(language === 'en' ? english : german);

  const country_map = countries.getNames(language, {select: "official"});
  const country_codes = Object.keys(country_map);

  const { t } = useTranslation();

  const validationSchema = Yup.object().shape({
    first_name: Yup.string()
      .required(t('messages.registration.errors.first_name.required')),

    last_name: Yup.string()
      .required(t('messages.registration.errors.last_name.required')),

    company_name: Yup.string()
      .required(t('messages.registration.errors.company_name.required')),

    position: Yup.string(),

    street: Yup.string()
      .required(t('messages.registration.errors.street.required')),

    zip: Yup.string()
      .required(t('messages.registration.errors.zip.required')),

    city: Yup.string()
      .required(t('messages.registration.errors.city.required')),

    country: Yup.string()
      .required(t('messages.registration.errors.country.required')),

    // username: Yup.string()
    //   .required('Username is required')
    //   .min(6, 'Username must be at least 6 characters')
    //   .max(20, 'Username must not exceed 20 characters'),

    email: Yup.string()
      .required(t('messages.registration.errors.email.required'))
      .email(t('messages.registration.errors.email.invalid')),

    phone_number: Yup.string(),

    // password: Yup.string()
    //   .required(t('messages.errors.password.required'))
    //   .min(6, t('messages.errors.password.min_length'))
    //   .max(40, t('messages.errors.password.max_length')),
    
    // password_confirm: Yup.string()
    //   .required(t('messages.errors.password_confirm.required'))
    //   .oneOf([Yup.ref('password'), null], t('messages.errors.password_confirm.does_not_match')),
    
    privacy_accepted: Yup.bool()
      .oneOf([true], t('messages.registration.errors.privacy_accepted.required')),

    terms_accepted: Yup.bool()
      .oneOf([true], t('messages.registration.errors.terms_accepted.required')),

    language: Yup.string()
        .max(5, 'Language must not exceed 5 characters'),

  });

  const { actions, state } = useStateMachine({ updateAction });
  const [, setRegistered] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    // defaultValues: {
    //   first_name: state.registration.first_name,
    //   last_name: state.registration.last_name,
    //   company: state.registration.company,
    //   email: state.registration.email,
    //   password: state.registration.password,
    //   password_confirm: state.registration.password_confirm,
    //   privacy_accepted: state.registration.privacy_accepted,
    //   terms_accepted: state.registration.terms_accepted,
    // }
  });
  
  console.log('Errors', errors);
  let navigate = useNavigate();
  let { plan, registration_id, join_token, encoded_company_name } = useParams();

  const onSubmit = async data => {

    // patch in plan
    data.product_reference = props.plan || plan;

    console.log('Posting registration', JSON.stringify(data, null, 2));
    const {result: res, error: err} = await new Api().register(data);

    if(res) {
      if(res.data.activation_token) {
        actions.updateAction({registration: {...data, status: true, message: t('messages.registration.successful')}});
        navigate('/register/thankyou');
      } else {
        actions.updateAction({registration: {...data, status: false, message: t('messages.registration.failed')}});
      }
      console.log('Result', res.data)
    } else if(err) {
      if(err.response) {
        if(err.response.data.code) {
            actions.updateAction({registration: {...data, status: false, message: err.response.data.message, code: err.response.data.code}});
        } else {
            actions.updateAction({registration: {...data, status: false, message: t('messages.registration.errors.request_failed_try_again')}});
            console.log('error', err);
        }
      } else if (err.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          actions.updateAction({registration: {...data, status: false, message: t('messages.registration.errors.request_failed_try_again')}});
          console.log('error', err.request);
      } else {
          // Something happened in setting up the request that triggered an Error
          actions.updateAction({registration: {...data, status: false, message: t('messages.registration.errors.request_failed_try_again')}});
          console.log('Error', err.message);
      }
      console.log(err.config);
    }

    setRegistered(true);
  };  

  console.log('Props', props);
  if(props.invitation) {
    console.log('Invite Params', registration_id, join_token, encoded_company_name);
  } else {
    console.log('No params for regular registration.');
  }
  
  const show_alert = state.registration?.message;
  
  return (
    <Row>
      <Col className="col-md-12">
        <div className="shop_3_teaser">
          <Plan plan={props.plan || plan} className="selected-plan"/>
    <div className="shop_item text-left registration-form">
      <div className="shop_item_inner">
        <div className={`model ${props.plan || plan}`}>
          <p>{t('messages.registration.title')}</p>
        </div>
        <div className="text">
        <form onSubmit={handleSubmit(onSubmit)}>

          <input
            name="join_token"
            type="hidden"
            defaultValue={props.invitation ? join_token : ''}
            {...register('join_token')}
          />

          <input
            name="language"
            type="hidden"
            defaultValue={language}
            {...register('language')}
          />

          <div className="form-group">
            <label>{t('messages.registration.first_name')}*</label>
            <input
              name="first_name"
              type="text"
              defaultValue={state.registration?.first_name}
              {...register('first_name')}
              className={`form-control ${errors.first_name ? 'is-invalid' : ''}`}
              />
            <div className="invalid-feedback">{errors.first_name?.message}</div>
          </div>

          <div className="form-group">
            <label>{t('messages.registration.last_name')}*</label>
            <input
              name="last_name"
              type="text"
              defaultValue={state.registration?.last_name}
              {...register('last_name')}
              className={`form-control ${errors.last_name ? 'is-invalid' : ''}`}
              />
            <div className="invalid-feedback">{errors.last_name?.message}</div>
          </div>

          <div className="form-group">
            <label>{t('messages.registration.job_title')}</label>
            <input
              name="job_title"
              type="text"
              defaultValue={state.registration?.job_title}
              {...register('job_title')}
              className={`form-control ${errors.job_title ? 'is-invalid' : ''}`}
              />
            <div className="invalid-feedback">{errors.job_title?.message}</div>
          </div>

          <div className="form-group">
            <label>{t('messages.registration.company_name')}*</label>
            <input
              name="company_name"
              type="text"
              defaultValue={ props.invitation ? encoded_company_name : state.registration?.company_name}
              readOnly={ props.invitation }
              {...register('company_name')}
              className={`form-control ${errors.company_name ? 'is-invalid' : ''}`}
              />
            <div className="invalid-feedback">{errors.company_name?.message}</div>
          </div>

          {
          props.invitation &&
        <div>
          <input name="street" type="hidden" defaultValue="-" {...register('street')}/>
          <input name="zip" type="hidden" defaultValue="-" {...register('zip')}/>
          <input name="city" type="hidden" defaultValue="-" {...register('city')}/>
          <input name="country" type="hidden" defaultValue="-" {...register('country')}/>
        </div>
          }

          {
            !props.invitation &&
        <div>
          <div className="form-group">
            <label>{t('messages.registration.street')}*</label>
            <input
              name="street"
              type="text"
              defaultValue={state.registration?.street}
              {...register('street')}
              className={`form-control ${errors.street ? 'is-invalid' : ''}`}
              />
            <div className="invalid-feedback">{errors.street?.message}</div>
          </div>

          <div className="form-group">
            <label>{t('messages.registration.zip')}*</label>
            <input
              name="zip"
              type="text"
              defaultValue={state.registration?.zip}
              {...register('zip')}
              className={`form-control ${errors.zip ? 'is-invalid' : ''}`}
              />
            <div className="invalid-feedback">{errors.zip?.message}</div>
          </div>

          <div className="form-group">
            <label>{t('messages.registration.city')}*</label>
            <input
              name="city"
              type="text"
              defaultValue={state.registration?.city}
              {...register('city')}
              className={`form-control ${errors.city ? 'is-invalid' : ''}`}
              />
            <div className="invalid-feedback">{errors.city?.message}</div>
          </div>

          <div className="form-group">
            <label>{t('messages.registration.country')}*</label>

            <select
              name="country"
              defaultValue={state.registration?.country || 'DE'}
              {...register('country')}
              className={`form-control ${errors.country ? 'is-invalid' : ''}`}
            >
              { country_codes.map((country_code, country_index) => 
                <option key={country_code} value={country_code}>{country_map[country_code]}</option>
              )}
            </select>
            <div className="invalid-feedback">{errors.country?.message}</div>
          </div>
        </div>
        }

          <div className="form-group">
            <label>{t('messages.registration.email')}*</label>
            <input
              name="email"
              type="email"
              defaultValue={state.registration?.email}
              {...register('email')}
              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
              />
            <div className="invalid-feedback">{errors.email?.message}</div>
          </div>

          <div className="form-group">
            <label>{t('messages.registration.phone_number')}</label>
            <input
              name="phone_number"
              type="text"
              defaultValue={state.registration?.phone_number}
              {...register('phone_number')}
              className={`form-control ${errors.phone_number ? 'is-invalid' : ''}`}
              />
            <div className="invalid-feedback">{errors.phone_number?.message}</div>
          </div>

          <input
            type="hidden"
            name="privacy_accepted"
            defaultValue={true} 
            {...register('privacy_accepted')}
          />

          <div className="form-group form-check">
            <input
              name="terms_accepted"
              type="checkbox"
              defaultChecked={state.registration?.terms_accepted}            
              {...register('terms_accepted')}
              className={`form-check-input ${
                errors.terms_accepted ? 'is-invalid' : ''
              }`}
            />
            <label htmlFor="terms_accepted" className="form-check-label"
            dangerouslySetInnerHTML={{__html:t('messages.registration.terms_accepted')+'*'}}>
            </label>
            <div className="invalid-feedback">{errors.terms_accepted?.message}</div>
          </div>

          <div className="form-group">
            <button type="submit" className="btn btn_bg">
              {t('messages.registration.register')}
            </button>
          </div>

          <p className="required-fields-info">{t('messages.registration.fields_with_asterisk_are_required')}</p>
        </form>    
        { show_alert && <Alert variant={state.registration.status ? 'success' : 'danger'}>{ state.registration.message }</Alert> }
      </div>
    </div>
    </div>
    </div>
    </Col>  
  </Row>
  );
}

export default RegistrationForm;