import React from 'react';
import { Outlet } from "react-router-dom";
import { Container } from 'react-bootstrap';
import Header from './Header';
import Debug from './Debug';
import {useDebug, useLanguage} from './Hooks';

import './App.scss';

export default function App(props) {

  const { debug } = useDebug();
  
  // needs to be here to properly pull/set the correct language
  const {language, setLanguage} = useLanguage();
  
  // make sure our session and i18n values match up to the user's request
  setLanguage(language);

  return (
    <main className="app">
      <Header />
      <Outlet />
      <section className="teaser shop_3 custom_cols">
      <Container className="container-fluid">
        { debug && <Debug /> }
      </Container>
      </section>
  </main>
);
}
