import React from "react";
import ContentBlock from "./ContentBlock";
import { useTranslation } from "react-i18next";

const Enterprise = props => {

  const { t } = useTranslation();

  return <ContentBlock {...props}
    model="enterprise"
    title="Enterprise"    
  >
    <div className="dauer">
      <p></p>
    </div>
    <div className="price">
      <p>{t('messages.products.lets_talk')}</p>
    </div>
    <div className="billing">
      <p>{t('messages.products.about_custom_package')}</p>
    </div>
    <div className="text">
    <h3>{t('messages.products.includes')}</h3>
      <p>
        + {t('messages.products.features.more_than_5_users')}<br />
        + {t('messages.products.features.vip_trainings')}<br />
        + {t('messages.products.features.personal_support')}<br />
        + {t('messages.products.features.customizations')}<br />
        + {t('messages.products.features.on_premise')}<br />
        + {t('messages.products.features.apis_and_integrations')}<br />
        + {t('messages.products.features.hardware_support_via_partner')}<br />
      </p>
    </div>
  </ContentBlock>
};

export default Enterprise;