import React from "react";
import { useTranslation } from 'react-i18next';
import Page from "./Page";
import Teaser from "./Teaser";

const TextPage = props => {
  const { t } = useTranslation();

  return <Page
    teaser={<Teaser headline={t(`messages.${props.message_key}.headline`)}>
      <p dangerouslySetInnerHTML={{__html: t(`messages.${props.message_key}.teaser`)}}></p>
      { props.extra }
    </Teaser>}>
    { props.children }
  </Page>;
};

export default TextPage;