function updateAction(state, payload) {
    
    console.log('state', state, 'payload', payload);

    return {
        ...state,
        ...payload,
    };
}

export default updateAction