import React from "react";
import { Container } from 'react-bootstrap';

const Page = props => {
  return <>
      { props.teaser}
      <section className="teaser shop_3 custom_cols">
      <Container className="container-fluid">
          { props.children }
      </Container>
      </section>
  </>
};

export default Page;