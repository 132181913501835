import React from "react";
import { Col, } from 'react-bootstrap';
import { useStateMachine } from "little-state-machine";
import updateAction from "./updateAction";

const Result = props => {
  const { state } = useStateMachine(updateAction);

  return <Col><pre>{JSON.stringify(state, null, 2)}</pre></Col>;
};

export default Result;